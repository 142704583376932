<template>
  <div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-end py-1">
        <b-col md="12" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack">Help</h3>
          <p class="font-weight-bold text-colorGray mb-50">
            You can inform us if you need any help
          </p>
          <hr class="px-3" />
        </b-col>
      </b-row>

      <validation-observer ref="helpFormValidation">
        <b-form class="auth-login-form mt-2 px-2" @submit.prevent>
          <b-row class="d-flex align-items-center">
            <b-col md="4">
              <h6 class="font-weight-bolder text-colorBlack">
                Institution name
              </h6>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="institutionName">
                <validation-provider
                  #default="{ errors }"
                  name="institutionName"
                  rules="required"
                >
                  <b-form-input
                    id="institutionName"
                    v-model="institutionName"
                    :state="errors.length > 0 ? false : null"
                    name="institutionName "
                    placeholder="Institution Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group></b-col
            >
          </b-row>
          <hr class="px-3" />
          <b-row class="d-flex align-items-center">
            <b-col md="4">
              <h6 class="font-weight-bolder text-colorBlack">Department</h6>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="department">
                <validation-provider
                  #default="{ errors }"
                  name="department"
                  rules="required"
                >
                  <b-form-input
                    id="department"
                    v-model="department"
                    :state="errors.length > 0 ? false : null"
                    name="department "
                    placeholder="Department"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group></b-col
            >
          </b-row>
          <hr class="px-3" />
          <b-row class="d-flex align-items-center">
            <b-col md="4">
              <h6 class="font-weight-bolder text-colorBlack">Start Date</h6>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="startDate">
                <validation-provider
                  #default="{ errors }"
                  name="startDate"
                  rules="required"
                >
                  <b-form-input
                    id="startDate"
                    v-model="startDate"
                    :state="errors.length > 0 ? false : null"
                    name="startDate"
                    placeholder="Start Date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group></b-col
            >
          </b-row>
          <hr class="px-3" />
          <b-row class="d-flex align-items-center">
            <b-col md="4">
              <h6 class="font-weight-bolder text-colorBlack">
                Expected Date To End
              </h6>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="endDate">
                <validation-provider
                  #default="{ errors }"
                  name="endDate"
                  rules="required"
                >
                  <b-form-input
                    id="endDate"
                    v-model="endDate"
                    :state="errors.length > 0 ? false : null"
                    name="endDate"
                    placeholder="End Date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group></b-col
            >
          </b-row>
          <hr class="px-3" />
          <b-row class="d-flex align-items-center">
            <b-col md="4">
              <h6 class="font-weight-bolder text-colorBlack">Progress</h6>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="progress">
                <validation-provider
                  #default="{ errors }"
                  name="progress"
                  rules="required"
                >
                  <b-form-input
                    id="progress"
                    v-model="progress"
                    :state="errors.length > 0 ? false : null"
                    name="progress"
                    placeholder="Progress"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group></b-col
            >
          </b-row>
          <hr class="px-3" />

          <b-row class="d-flex align-items-center">
            <b-col md="4">
              <h6 class="font-weight-bolder text-colorBlack">Upload File</h6>
              <h6 class="font-weight-bolder text-colorGray">
                This will be displayed on admin site.
              </h6>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="file">
                <validation-provider
                  #default="{ errors }"
                  name="file"
                  rules="required"
                >
                  <b-form-file
                    id="file"
                    v-model="file"
                    :state="errors.length > 0 ? false : null"
                    name="file"
                    placeholder="file"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group></b-col
            >
          </b-row>
          <hr class="px-3" />
          <div class="text-right pb-2">
            <b-button
              type="submit"
              variant="colorGray"
              class="mr-1"
              rounded
              :to="{ name: 'Dashboard' }"
            >
              Cancel
            </b-button>
            <b-button
              type="submit"
              variant="colorBlue"
              @click="openModal"
              rounded
            >
              Sent
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </div>
    <HelpModal :modalVisible="modalVisible" @close="closeModal" />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { $themeConfig } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
import HelpModal from "@/components/student/help/HelpModal.vue";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    HelpModal,
  },
  mixins: [togglePasswordVisibility, util],
  data() {
    return {
      appName: "",
      appFullName: "",
      appFullName: "",
      institutionName: "",
      department: "",
      startDate: "",
      endDate: "",
      progress: "",
      file: "",
      required,
      modalVisible: false,
    };
  },
  created() {
    this.appName = $themeConfig.app.appName;
    this.appFullName = $themeConfig.app.appFullName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
  },
  methods: {
    async validateForm() {
      const success = await this.$refs.helpFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },

    openModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    ...mapGetters({ hasRole: "appData/hasRole" }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped></style>
