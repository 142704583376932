var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bg-colorWhite",staticStyle:{"border-radius":"20px"}},[_c('b-row',{staticClass:"d-flex align-items-start justify-content-end py-1"},[_c('b-col',{staticClass:"px-3 py-1",attrs:{"md":"12"}},[_c('h3',{staticClass:"font-weight-bolder text-colorBlack"},[_vm._v("Help")]),_c('p',{staticClass:"font-weight-bold text-colorGray mb-50"},[_vm._v(" You can inform us if you need any help ")]),_c('hr',{staticClass:"px-3"})])],1),_c('validation-observer',{ref:"helpFormValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2 px-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"md":"4"}},[_c('h6',{staticClass:"font-weight-bolder text-colorBlack"},[_vm._v(" Institution name ")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"institutionName"}},[_c('validation-provider',{attrs:{"name":"institutionName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"institutionName","state":errors.length > 0 ? false : null,"name":"institutionName ","placeholder":"Institution Name"},model:{value:(_vm.institutionName),callback:function ($$v) {_vm.institutionName=$$v},expression:"institutionName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr',{staticClass:"px-3"}),_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"md":"4"}},[_c('h6',{staticClass:"font-weight-bolder text-colorBlack"},[_vm._v("Department")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"department"}},[_c('validation-provider',{attrs:{"name":"department","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"department","state":errors.length > 0 ? false : null,"name":"department ","placeholder":"Department"},model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr',{staticClass:"px-3"}),_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"md":"4"}},[_c('h6',{staticClass:"font-weight-bolder text-colorBlack"},[_vm._v("Start Date")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"startDate"}},[_c('validation-provider',{attrs:{"name":"startDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"startDate","state":errors.length > 0 ? false : null,"name":"startDate","placeholder":"Start Date"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr',{staticClass:"px-3"}),_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"md":"4"}},[_c('h6',{staticClass:"font-weight-bolder text-colorBlack"},[_vm._v(" Expected Date To End ")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"endDate"}},[_c('validation-provider',{attrs:{"name":"endDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"endDate","state":errors.length > 0 ? false : null,"name":"endDate","placeholder":"End Date"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr',{staticClass:"px-3"}),_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"md":"4"}},[_c('h6',{staticClass:"font-weight-bolder text-colorBlack"},[_vm._v("Progress")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"progress"}},[_c('validation-provider',{attrs:{"name":"progress","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"progress","state":errors.length > 0 ? false : null,"name":"progress","placeholder":"Progress"},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr',{staticClass:"px-3"}),_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"md":"4"}},[_c('h6',{staticClass:"font-weight-bolder text-colorBlack"},[_vm._v("Upload File")]),_c('h6',{staticClass:"font-weight-bolder text-colorGray"},[_vm._v(" This will be displayed on admin site. ")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"file"}},[_c('validation-provider',{attrs:{"name":"file","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"file","state":errors.length > 0 ? false : null,"name":"file","placeholder":"file"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr',{staticClass:"px-3"}),_c('div',{staticClass:"text-right pb-2"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"colorGray","rounded":"","to":{ name: 'Dashboard' }}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"type":"submit","variant":"colorBlue","rounded":""},on:{"click":_vm.openModal}},[_vm._v(" Sent ")])],1)],1)],1)],1),_c('HelpModal',{attrs:{"modalVisible":_vm.modalVisible},on:{"close":_vm.closeModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }