<template>
  <b-modal
    id="help-modal"
    v-model="isModalVisible"
    :hide-header="true"
    :hide-footer="true"
    centered
    @hide="emitClose"
  >
    <div class="mt-1 text-center">
      <b-link :to="{ name: 'Login' }">
        <img
          src="@/assets/images/icons/Tick.svg"
          img-fluid
          width="200"
          height="75"
      /></b-link>
    </div>

    <div class="text-center my-1">
      <h3 class="font-weight-bolder text-colorBlack">
        Successfully Sent Help Request
      </h3>
      <p class="mt-2 font-weight-bold text-colorGray">
        We will inform you soon once admin can review your request and we will
        send you request.
      </p>
    </div>
    <div class="text-center my-1">
      <b-button
        type="submit"
        variant="colorBlue"
        block
        class="mt-3"
        :to="{ name: 'Dashboard' }"
      >
        Go To Dashboard
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    modalVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  watch: {
    modalVisible(value) {
      this.isModalVisible = value;
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
